<template>
    <NuxtLink
        v-if="featuredEntry"
        :to="localePath(`/${featuredEntry.uri}`)"
        class="base-card"
    >
        <BaseImage
            class="base-card__image base-image--cover"
            :src="singleImage?.w320"
            :srcset="`
                ${singleImage?.w320} 320w,
                ${singleImage?.w768} 768w,
                ${singleImage?.w980} 980w,
                ${singleImage?.w1260} 1260w
            `"
            sizes="
                (max-width: 380px) 320px,
                768px
            "
            :height="singleImage?.height"
            :width="singleImage?.width"
            alt=""
        />

        <div class="base-card__meta">
            <MetaLine>
                <slot name="cardType" />
                {{ !$slots.cardType ? cardType : null }}

                <template v-if="featuredEntry.date || featuredEntry.dateRange || featuredEntry.vacancyStartDate" #after>
                    <DateOrDateRange :entry="featuredEntry" />
                </template>
            </MetaLine>

            <div class="base-card__line" />
            <div v-if="$slots.date" class="base-card__date">
                <slot name="date" />
            </div>

            <div v-if="featuredEntry.date && !$slots.date" class="base-card__date">
                {{ $d(featuredEntry.date) }}
            </div>
        </div>
        <h4 class="base-card__title">
            {{ featuredEntry.title }}
        </h4>
        <div v-if="featuredEntry.location" class="base-card__logo">
            <LocationLogo :location="featuredEntry.location" :compact="true" />
        </div>
    </NuxtLink>
</template>

<script setup>
const props = defineProps({
    featuredEntry: {
        type: Object,
        required: true,
        default: () => {}
    },
});

const localePath = useLocalePath();
const { t } = useI18n({ useScope: 'local' });

const singleImage = computed(() => {
    if (props.featuredEntry?.images) {
        return props.featuredEntry?.images[0];
    } else if (props.featuredEntry?.image?.length) {
        return props.featuredEntry?.image[0];
    }
    return props.featuredEntry?.image;
});

const cardType = computed(() => {
    if (props.featuredEntry.__typename === 'activity_default_Entry') {
        if (props.featuredEntry.permanentExhibtion) {
            return t('temporaryExhibition');
        }

        return t('permanentExhibition');
    }

    if (props.featuredEntry.__typename === 'route_default_Entry') {
        return t('sections.route');
    }

    if (
        props.featuredEntry.__typename === 'education_default_Entry' &&
        props.featuredEntry.educationContentType.length > 0
    ) {
        return props.featuredEntry.educationContentType[0].title;
    }

    if (props.featuredEntry.__typename === 'collection_default_Entry') {
        if (props.featuredEntry.collectionType.length > 0) {
            return props.featuredEntry.collectionType[0].title;
        }

        return t('sections.collection');
    }

    if (props.featuredEntry.__typename === 'agenda_default_Entry') {
        return props.featuredEntry.eventType[0].title;
    }

    if (props.featuredEntry.__typename === 'vacancy_default_Entry') {
        return t('sections.vacancy');
    }

    if (
        props.featuredEntry.__typename === 'page_page_Entry' &&
        props.featuredEntry.pageType && props.featuredEntry.pageType !== 'default'
    ) {
        return t(`pageType.${props.featuredEntry.pageType}`);
    }

    if (props.featuredEntry.__typename === 'theme_default_Entry') {
        return t('sections.theme');
    }

    if (
        props.featuredEntry.__typename === 'pressContent_default_Entry' &&
        props.featuredEntry.pressType.length > 0
    ) {
        return props.featuredEntry.pressType[0].title ?? null;
    }

    if (props.featuredEntry.__typename === 'space_default_Entry') {
        return t('sections.space');
    }

    return '';
});
</script>

<style src="./BaseCard.less" lang="less"></style>

<i18n>
{
    "nl": {
        "permanentExhibition": "Vaste tentoonstelling",
        "temporaryExhibition": "Tentoonstelling",
        "pageType": {
            "program": "Program"
        }
    },
    "en": {
        "permanentExhibition": "Permanent exhibition",
        "temporaryExhibition": "Exhibition",
        "pageType": {
            "program": "Program"
        }
    }
}
</i18n>
